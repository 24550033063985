export const seo = {
  url: 'career',
  title: {
    en: 'Career in a transport company that focuses on success',
    es: 'Carrera en una empresa de transporte que se centra en el éxito',
    ro: 'Carieră într-o companie de transport care se concentrează pe succes',
  },
  desc: {
    en: 'Join the leading companies in the TFL industry in Europe. Develop yourself in the area of ​​transport, forwarding and logistics. Check how it is working in Omida!',
    es: 'Únete a las empresas líderes en la industria TFL en Europa. Desarróllate en el área de transporte, expedición y logística. ¡Comprueba cómo funciona en Omida!',
    ro: 'Alăturați-vă celor mai importante companii din industria TFL din Europa. Dezvoltați-vă în zona de transport, expediere și logistică. Verificați cum funcționează în Omida!',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    en: 'Career',
    es: 'Carrera',
    ro: 'Carieră',
  },
  desc: {
    en: 'Join the leading companies in the TFL industry in Europe. Develop yourself in the area of ​​transport, forwarding and logistics. Check how it is working in Omida!',
    es: 'Únete a las empresas líderes en la industria TFL en Europa. Desarróllate en el área de transporte, expedición y logística. ¡Comprueba cómo funciona en Omida!',
    ro: 'Alăturați-vă celor mai importante companii din industria TFL din Europa. Dezvoltați-vă în zona de transport, expediere și logistică. Verificați cum funcționează în Omida!',
  },
  button: {
    text: {
      en: 'Check opportunities',
      es: 'Ver oportunidades',
      ro: 'Verificați oportunitățile',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'Start your career in a transport company that focuses on success',
        es: 'Comienza tu carrera en una empresa de transporte que se enfoca en el éxito',
        ro: 'Începe-ți cariera într-o companie de transport care se concentrează pe succes',
      },
      texts: [
        {
          en: '<span><strong>Dynamic development</strong> and the structure of OMIDA Group allows <strong>to make key decisions quickly</strong>. A set of ISO certificates increase the sales possibilities of our forwarders and the reputation of the Omida brand is the key to <strong>greater possibilities</strong>.</span>',
          es: '<span><strong>El desarrollo dinámico</strong> y la estructura de OMIDA Group permite <strong>tomar decisiones clave rápidamente</strong>. Un conjunto de certificados ISO aumentan las posibilidades de venta de nuestros autocargadores y la reputación de la marca Omida es la clave para <strong>mayores posibilidades</strong>.</span>',
          ro: '<span><strong>Dezvoltarea dinamică</strong> și structura grupului Omida permit <strong>luarea rapidă a deciziilor cheie</strong>. Un set de certificate ISO măresc posibilitățile de vânzare ale expeditorilor noștri, iar reputația mărcii Omida este cheia pentru <strong>posibilități mai mari</strong>.</span>',
        },
        {
          en: '<span>We also constantly want to develop the areas of operation of Omida Logistics, and thus open new <strong>operational and commercial offices</strong> in our country and in Europe. Being at the forefront of TFL companies in Poland, we place emphasis on the key aspect in the implementation of <strong>dynamic development strategy</strong>, i.e. increasing the number of customers, transports and <strong>investment in people</strong>, who are a key element by Omida.</span>',
          es: '<span>También queremos constantemente desarrollar las áreas de operación de Omida Logistics, y así abrir nuevas <strong>oficinas operativas y comerciales</strong> en nuestro país y en Europa. Al estar a la vanguardia de las empresas de TFL en Polonia, ponemos énfasis en el aspecto clave en la implementación de la <strong>estrategia de desarrollo dinámico</strong>, es decir, aumentar el número de clientes, transportes e <strong>inversión en personas</strong >, que son un elemento clave de Omida.</span>',
          ro: '<span>De asemenea, ne dorim constant să dezvoltăm zonele de operare ale Omida Logistics, și astfel să deschidem noi <strong>birouri operaționale și comerciale</strong> în țara noastră și în Europa. Fiind în fruntea companiilor TFL din Polonia, punem accent pe aspectul cheie în implementarea <strong>strategiei de dezvoltare dinamică</strong>, adică creșterea numărului de clienți, transporturi și <strong>investiții în oameni</strong> >, care sunt un element cheie al lui Omida.</span>',
        },
      ],
    },
  ],
  features: [
    {
      icon: 'career1',
      subtitle: {
        en: 'Growth ',
        es: 'Crecimiento ',
        ro: 'Creștere ',
      },
      title: {
        en: '100%',
        es: '100%',
        ro: '100%',
      },
      desc: {
        en: `We offer our employees a unique career path in TFL focused on achieving success. It's up to you how quickly you achieve success`,
        es: 'Ofrecemos a nuestros empleados una trayectoria profesional única en TFL centrada en lograr el éxito. Depende de ti qué tan rápido alcances el éxito',
        ro: 'Oferim angajaților noștri o carieră unică în TFL, concentrată pe obținerea succesului. Depinde de tine cât de repede obții succesul',
      },
    },
    {
      icon: 'career2',
      subtitle: {
        en: 'Bonus ',
        es: 'Sistema de ',
        ro: 'Primă',
      },
      title: {
        en: 'System',
        es: 'Bonificación',
        ro: 'Sistem',
      },
      desc: {
        en: 'Motivated already? We have prepared a unique bonus system for you!',
        es: '¿Ya estás motivado? ¡Hemos preparado un sistema de bonificación único para ti!',
        ro: 'Deja motivat? Am pregătit un sistem unic de bonusuri pentru tine!',
      },
    },
    {
      icon: 'career3',
      subtitle: {
        en: 'Full ',
        es: 'Completo ',
        ro: 'Deplin',
      },
      title: {
        en: 'Focus ',
        es: 'Enfoque ',
        ro: 'Concentrează-te',
      },
      desc: {
        en: 'You only have to do what you are best at. Our forwarders have at their disposal internal legal, debt collection, marketing and HR departments',
        es: 'Solo tienes que hacer lo que mejor se te da. Nuestros transitarios tienen a su disposición departamentos internos jurídicos, de cobranza, de marketing y de recursos humanos',
        ro: 'Trebuie doar să faci ceea ce ești cel mai bun. Expeditorii noștri au la dispoziție departamente interne juridice, colectare creanțe, marketing și HR',
      },
    },
    {
      icon: 'career4',
      subtitle: {
        en: 'Any type of',
        es: 'Selección de',
        ro: 'Orice tip de',
      },
      title: {
        en: 'Contract',
        es: 'Contrato',
        ro: 'Contracta',
      },
      desc: {
        en: 'It is up to you to decide whether we cooperate on a B2B or an employment contract',
        es: 'Depende de usted decidir si cooperamos en un B2B o en un contrato de trabajo',
        ro: 'Depinde de dvs. să decideți dacă cooperăm pe un B2B sau un contract de muncă',
      },
    },
    {
      icon: 'career5',
      subtitle: {
        en: 'Support for',
        es: 'Apoyo a las',
        ro: 'Suport pentru',
      },
      title: {
        en: 'Hobbies',
        es: 'Aficiones',
        ro: 'Hobby-uri',
      },
      desc: {
        en: 'We know how important your hobby is to you. For years, we have been supporting our employees in making their dreams and goals come true. Join us and be part of the team',
        es: 'Sabemos lo importante que es tu hobby para ti. Durante años, hemos estado apoyando a nuestros empleados para que sus sueños y metas se hagan realidad. Únete a nosotros y sé parte del equipo',
        ro: 'Știm cât de important este hobby-ul tău pentru tine. De ani de zile, ne sprijinim angajații în realizarea viselor și obiectivelor lor. Alăturați-vă nouă și fiți parte din echipă',
      },
    },
    {
      icon: 'career6',
      subtitle: {
        en: 'Great',
        es: 'Buena',
        ro: 'Grozav',
      },
      title: {
        en: 'Location',
        es: 'Ubicación',
        ro: 'Locație',
      },
      desc: {
        en: 'We have offices in the largest business centers, in well-connected city districts. Join one of our branches now!',
        es: 'Contamos con oficinas en los mayores centros de negocios, en distritos de la ciudad bien comunicados. ¡Únete a una de nuestras sucursales ahora!',
        ro: 'Avem birouri în cele mai mari centre de afaceri, în cartierele orașului bine conectate. Alătură-te acum uneia dintre filialele noastre!',
      },
    },
  ],
}

export const video = {
  title: {
    en: 'Aim high. Join the strong one',
    es: 'Apuntar alto. Únete al fuerte',
    ro: 'Țintește sus. Alătură-te celui puternic',
  },
  url: 'https://www.youtube.com/watch?v=cC89OhOTSZk',
  more: [
    'https://www.youtube.com/watch?v=hnPotrKxOVs',
    'https://www.youtube.com/watch?v=w_7GdMYcgLg',
    'https://www.youtube.com/watch?v=HMkamapqpaY',
    'https://www.youtube.com/watch?v=otea6QesV1Y',
  ],
}

export const jobs = {
  title: {
    en: 'Choose your city. Apply now!',
    es: 'Elige tu ciudad. ¡Aplica ya!',
    ro: 'Alege-ți orașul. Aplicați acum!',
  },
  desc: {
    en: 'We have branches in over 20 locations in Poland. Would you like to join us with your team? Contact us at hr@omida.pl',
    es: 'Tenemos sucursales en más de 20 ciudades de Polonia. ¿Te gustaría unirte a nosotros con tu equipo? Póngase en contacto con nosotros en hr@omida.pl',
    ro: 'Avem filiale în peste 20 de locații din Polonia. Ați vrea să vă alăturați echipei? Contactați-ne la hr@omida.pl',
  },
}
